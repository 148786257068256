import LARGE_ALL_SBX from './opt-out-tenants/large-allsbx.json';
import LARGE_ALL_PROD from './opt-out-tenants/large-allprod.json';
import LARGE_CSBX from './opt-out-tenants/large-csbx.json';
import INVOICE_DETAIL_OPT_IN_PRODUCTION from './invoice-detail/opt-in-production.json';
import INVOICE_DETAIL_OPT_IN_STAGING from './invoice-detail/opt-in-staging.json';
import INVOICE_DETAIL_OPT_IN_SANDBOX from './invoice-detail/opt-in-sandbox.json';
import INVOICE_DETAIL_OPT_OUT_STAGING from './invoice-detail/opt-out-staging.json';
import INVOICE_DETAIL_OPT_OUT_SANDBOX from './invoice-detail/opt-out-sandbox.json';
import INVOICE_DETAIL_OPT_OUT_PRODUCTION from './invoice-detail/opt-out-production.json';
import PAYMENT_DETAIL_OPT_IN_PRODUCTION from './payment-detail/opt-in-production.json';
import PAYMENT_DETAIL_OPT_IN_STAGING from './payment-detail/opt-in-staging.json';
import PAYMENT_DETAIL_OPT_IN_SANDBOX from './payment-detail/opt-in-sandbox.json';
import PAYMENT_DETAIL_OPT_OUT_STAGING from './payment-detail/opt-out-staging.json';
import PAYMENT_DETAIL_OPT_OUT_SANDBOX from './payment-detail/opt-out-sandbox.json';
import PAYMENT_DETAIL_OPT_OUT_PRODUCTION from './payment-detail/opt-out-production.json';
import GLOBAL_TAX_HUB_OPT_IN_STAGING from './global-tax-hub/opt-in-staging.json';
import GLOBAL_TAX_HUB_OPT_IN_SANDBOX from './global-tax-hub/opt-in-sandbox.json';
import GLOBAL_TAX_HUB_OPT_IN_PRODUCTION from './global-tax-hub/opt-in-production.json';
import GLOBAL_TAX_HUB_OPT_OUT_STAGING from './global-tax-hub/opt-out-staging.json';
import GLOBAL_TAX_HUB_OPT_OUT_SANDBOX from './global-tax-hub/opt-out-sandbox.json';
import GLOBAL_TAX_HUB_OPT_OUT_PRODUCTION from './global-tax-hub/opt-out-production.json';

import { EnvironmentFlag } from '../types';
import { getEnvironmentFlag } from '../utils';

const optOutTenants = {
  [EnvironmentFlag.Development]: [],
  [EnvironmentFlag.Staging]: [],
  [EnvironmentFlag.Sandbox]: [],
  [EnvironmentFlag.Production]: [],
};

export function getOptOutTenants(hostname: string): string[] {
  return [...optOutTenants[getEnvironmentFlag(hostname)]];
}

const invoiceDetailOptInTenants = {
  [EnvironmentFlag.Development]: [],
  [EnvironmentFlag.Staging]: [...INVOICE_DETAIL_OPT_IN_STAGING],
  [EnvironmentFlag.Sandbox]: [...INVOICE_DETAIL_OPT_IN_SANDBOX],
  [EnvironmentFlag.Production]: [...INVOICE_DETAIL_OPT_IN_PRODUCTION],
};

export function getInvoiceDetailOptInTenants(hostname: string): string[] {
  return [...invoiceDetailOptInTenants[getEnvironmentFlag(hostname)]];
}

const invoiceDetailOptOutTenants = {
  [EnvironmentFlag.Development]: [],
  [EnvironmentFlag.Staging]: [...INVOICE_DETAIL_OPT_OUT_STAGING],
  [EnvironmentFlag.Sandbox]: [...INVOICE_DETAIL_OPT_OUT_SANDBOX],
  [EnvironmentFlag.Production]: [...INVOICE_DETAIL_OPT_OUT_PRODUCTION],
};

export function getInvoiceDetailOptOutTenants(hostname: string): string[] {
  return [...invoiceDetailOptOutTenants[getEnvironmentFlag(hostname)]];
}

const paymentDetailOptInTenants = {
  [EnvironmentFlag.Development]: [],
  [EnvironmentFlag.Staging]: [...PAYMENT_DETAIL_OPT_IN_STAGING],
  [EnvironmentFlag.Sandbox]: [...PAYMENT_DETAIL_OPT_IN_SANDBOX],
  [EnvironmentFlag.Production]: [...PAYMENT_DETAIL_OPT_IN_PRODUCTION],
};

export function getPaymentDetailOptInTenants(hostname: string): string[] {
  return [...paymentDetailOptInTenants[getEnvironmentFlag(hostname)]];
}

const paymentDetailOptOutTenants = {
  [EnvironmentFlag.Development]: [],
  [EnvironmentFlag.Staging]: [...PAYMENT_DETAIL_OPT_OUT_STAGING],
  [EnvironmentFlag.Sandbox]: [
    ...PAYMENT_DETAIL_OPT_OUT_SANDBOX,
    ...LARGE_CSBX,
    ...LARGE_ALL_SBX,
  ],
  [EnvironmentFlag.Production]: [
    ...PAYMENT_DETAIL_OPT_OUT_PRODUCTION,
    ...LARGE_ALL_PROD,
  ],
};

export function getPaymentDetailOptOutTenants(hostname: string): string[] {
  return [...paymentDetailOptOutTenants[getEnvironmentFlag(hostname)]];
}

const GlobalTaxHubOptInTenants = {
  [EnvironmentFlag.Development]: [],
  [EnvironmentFlag.Staging]: [...GLOBAL_TAX_HUB_OPT_IN_STAGING],
  [EnvironmentFlag.Sandbox]: [...GLOBAL_TAX_HUB_OPT_IN_SANDBOX],
  [EnvironmentFlag.Production]: [...GLOBAL_TAX_HUB_OPT_IN_PRODUCTION],
};

export function getGlobalTaxHubOptInTenants(hostname: string): string[] {
  return [...GlobalTaxHubOptInTenants[getEnvironmentFlag(hostname)]];
}

const GlobalTaxHubOptOutTenants = {
  [EnvironmentFlag.Development]: [],
  [EnvironmentFlag.Staging]: [...GLOBAL_TAX_HUB_OPT_OUT_STAGING],
  [EnvironmentFlag.Sandbox]: [...GLOBAL_TAX_HUB_OPT_OUT_SANDBOX],
  [EnvironmentFlag.Production]: [...GLOBAL_TAX_HUB_OPT_OUT_PRODUCTION],
};

export function getGlobalTaxHubOptOutTenants(hostname: string): string[] {
  return [...GlobalTaxHubOptOutTenants[getEnvironmentFlag(hostname)]];
}
