import { RedirectHandler } from '..';
import { PageType } from '../../types';
import {
  getDetailRedirect,
  getCreateRedirect,
  getBasePathForObject,
} from '../utils';
import { getListParams } from '../utils/getFilterParam';

export const SubscriptionRedirectHandler: RedirectHandler = ({
  queryParams,
}) => {
  const basePath = getBasePathForObject('subscription');

  const detailRedirect = getDetailRedirect(basePath, queryParams);
  const createRedirect = getCreateRedirect(basePath, queryParams);

  if (detailRedirect) {
    return detailRedirect;
  }

  if (createRedirect) {
    return createRedirect;
  }

  return {
    pageType: PageType.list,
    redirectUrl: basePath,
    params: getListParams(queryParams),
  };
};
