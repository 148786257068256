import { getOptOutTenants } from '../../constants/tenantList';
import { getTenantId } from '../../utils';
import { SkipRedirectHandler } from '../type';

export const DefaultSkipRedirectHandler: SkipRedirectHandler = async (
  location,
) => {
  let currentTenantId;
  try {
    currentTenantId = await getTenantId();
  } catch (error) {
    return true;
  }

  const isOptedOutTenant =
    !!currentTenantId &&
    getOptOutTenants(location.hostname).indexOf(currentTenantId) > -1;

  if (isOptedOutTenant) {
    console.warn(
      `Tenant ID ${currentTenantId} is in the tenant opt-out list for ${location.hostname}`,
    );
  }

  return isOptedOutTenant;
};
