import { RedirectHandler } from '..';
import { PageType } from '../../types';
import {
  getBasePathForObject,
  getCreateRedirect,
  getDetailRedirect,
} from '../utils';
import { getListParams } from '../utils/getFilterParam';

export const RefundRedirectHandler: RedirectHandler = ({ queryParams }) => {
  if (
    queryParams?.refundNumberWithFormat ||
    ['create', 'view'].includes(queryParams?.method?.toString() ?? '')
  ) {
    return;
  }

  const basePath = getBasePathForObject('refund');

  const detailRedirect = getDetailRedirect(basePath, queryParams);
  const createRedirect = getCreateRedirect(basePath, queryParams);

  if (detailRedirect) {
    return detailRedirect;
  }

  if (createRedirect) {
    return createRedirect;
  }

  return {
    pageType: PageType.list,
    redirectUrl: basePath,
    params: getListParams(queryParams),
  };
};
