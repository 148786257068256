import { RedirectHandler } from '..';
import { PageType } from '../../types';
import Mustache from 'mustache';
import { getListParams } from '../utils/getFilterParam';

export const CustomObjectRedirectHandler: RedirectHandler = ({
  location,
  queryParams,
}) => {
  const urlPattern = {
    customObjectMain: '/platform/custom-objects',
    customObject: '/platform/custom-objects/default',
    records: '/records',
    recordDetail: '/details',
    createRecord: '/records/new',
    create: '/new',
  };
  const urlMap = {
    customObjectMain: '/platform/apps/default/definitions',
    customObject: '/platform/apps/default/definitions',
    records: '/platform/apps/default/{{customObjectId}}/definitions',
    recordDetail: '/platform/apps/default/{{customObjectId}}/definitions',
    createRecord: '/platform/apps/default/{{customObjectId}}/definitions',
    create: '/platform/apps/default/definitions/create',
  };
  const pageTypeMap = {
    customObjectMain: PageType.list,
    customObject: PageType.list,
    records: PageType.list,
    recordDetail: PageType.detail,
    createRecord: PageType.create,
    create: PageType.create,
  };

  const url =
    location.href[location.href.length] === '/'
      ? location.href.slice(0, -1)
      : location.href;
  const pathParams = {};

  let foundKey: string | number = Object.values(urlPattern).findIndex((key) =>
    url.endsWith(key),
  );
  if (foundKey >= 0) foundKey = Object.keys(urlPattern)[foundKey];

  if (
    foundKey === 'records' ||
    foundKey === 'recordDetail' ||
    foundKey === 'createRecord'
  ) {
    pathParams['customObjectId'] = url.substring(
      url.indexOf(urlPattern.customObject) + urlPattern.customObject.length + 1,
      url.indexOf(urlPattern.records),
    );
  }
  if (foundKey === 'recordDetail') {
    pathParams['Id'] = url.substring(
      url.indexOf(urlPattern.records) + urlPattern.records.length + 1,
      url.indexOf(urlPattern.recordDetail),
    );
  }

  const replatformedUrl =
    foundKey && urlMap[foundKey]
      ? Mustache.render(urlMap[foundKey], pathParams)
      : undefined;

  const pageType = pageTypeMap[foundKey] || PageType.list;

  return {
    pageType,
    redirectUrl: replatformedUrl,
    ...(PageType.list === pageType
      ? { params: getListParams(queryParams) }
      : { queryParams }),
  };
};
