import { getIdentity, getTenantId } from '../../utils';
import { Identity } from '../../types';
import {
  getPaymentDetailOptInTenants,
  getPaymentDetailOptOutTenants,
} from '../../constants/tenantList';
import { SkipRedirectHandler } from '../type';

export const PaymentDetailSkipRedirectHandler: SkipRedirectHandler = async (
  location,
) => {
  let currentTenantId;
  try {
    currentTenantId = await getTenantId();
  } catch (error) {
    return true;
  }

  const optOutTenants = getPaymentDetailOptOutTenants(location.hostname);
  if (currentTenantId && optOutTenants.indexOf(currentTenantId) > -1) {
    return true;
  }

  // when whitelist is empty, it means toggle on for all tenants
  const optInTenants = getPaymentDetailOptInTenants(location.hostname);
  if (
    currentTenantId &&
    optInTenants.length > 0 &&
    optInTenants.indexOf(currentTenantId) === -1
  ) {
    return true;
  }

  const identity: Identity = await getIdentity();
  const arSettlementEnabled =
    identity.permissions.indexOf('permission.ARSettlement') > -1;

  return !arSettlementEnabled;
};
