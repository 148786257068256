import { PageType } from './types';

export default {
  [PageType.list]: {
    customer_id: 'AccountId',
    invoice_owner_id: 'InvoiceOwnerId',
    accountId: 'AccountId',
    subscriptionType: 'Type', // This is only for subscription list and the value is "Standard" or "OmniChannel"
    // This is only for Usage AFAIK and is always AccountNumber
    FindKey: 'AccountNumber',
  },
  [PageType.detail]: {
    id: 'Id',
    refundNumberWithFormat: 'RefundNumber',
    invoice_number: 'Id',
    invoiceKey: 'Id',
    batchNumber: 'BatchNumber',
    number: 'Number',
  },
  [PageType.edit]: {
    id: 'Id',
    refundNumberWithFormat: 'RefundNumber',
    method: {
      conditions: ['editSimple', 'editSchedule', 'edit', 'apply'],
    },
  },
  [PageType.create]: {
    id: 'Id',
    subscriptionid: 'SubscriptionId',
    subscriptionId: 'SubscriptionId',
    pId: 'ProductId',
    accountId: 'AccountId',
    method: {
      conditions: ['editSimple', 'editSchedule', 'edit', 'apply'],
    },
    invoiceId: 'InvoiceId',
  },
};
