import { PageType } from './types';

export default {
  '/apps/NewInvoice.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/invoice',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
    [PageType.detail]: {
      url: '/platform/apps/com_zuora/invoice/{{Id}}',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/apps/NewBillingRun.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/billrun',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/apps/Usages.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/usage',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/apps/Product.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/productcatalog',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/apps/Feature.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/feature',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/apps/NewPayment.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/payment',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
    [PageType.detail]: {
      url: '/platform/apps/com_zuora/payment/{{Id}}',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: false,
      },
    },
  },
  '/apps/ARPayment.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/payment',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
    [PageType.detail]: {
      url: '/platform/apps/com_zuora/payment/{{Id}}',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: false,
      },
    },
  },
  '/apps/PaymentRun.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/paymentrun',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/apps/NewPaymentMethod.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/paymentmethod',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/apps/ARRefund.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/refund',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/apps/NewRefund.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/refund',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/apps/JournalRuns.dox': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/journalrun',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/apps/AccountingPeriods.dox': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/accountingperiod',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/orders': {
    // Current Orders is UNSAFE to redirect
    // https://zuora.atlassian.net/browse/PD-629
    // [PageType.list]: '/platform/apps/com_zuora/order',
    // [PageType.detail]: '/platform/apps/com_zuora/order/{{OrderNumber}}',
    // [PageType.edit]: '/platform/apps/com_zuora/order/{{OrderNumber}}',
    // [PageType.create]: '/platform/apps/com_zuora/order/create',
  },
  '/apps/UpdaterBatch.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/updaterbatch',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/apps/Reconciliation.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/paymentreconciliationjob',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  // TODO: This will always route to Credit Memo. The existing screen has both on the same page, so it's impossible to tell via URL which to go to.
  '/apps/ARSettlement.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/creditdebitmemos',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/apps/NewAmendment.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/amendment',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/apps/CustomerAccount.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/account',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/apps/Subscription.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/subscription',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
    // ZUI-5469: Subscription Detail redirects handled in Java, do NOT implement here.
  },
  '/apps/Adjustment.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/invoiceadjustment',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/apps/InvoiceItemAdjustment.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/invoiceitemadjustment',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/platform/custom-objects': {
    [PageType.all]: {
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/platform/data-connect': {
    [PageType.all]: {
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
      isEnabledForOldUI: true,
    },
  },
  '/apps/CustomerNotifications.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/notifications',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/apps/NotificationHistory.do': {
    [PageType.list]: {
      url: "/platform/apps/com_zuora/notifications?~(selectedTab~'email-history-tab)",
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
    [PageType.list]: {
      url: "/platform/apps/com_zuora/notifications?~(selectedTab~'callout-history-tab)",
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
    },
  },
  '/apps/CustomEvent.do': {
    [PageType.list]: {
      url: "/platform/apps/com_zuora/notifications?~(selectedTab~'custom-events-tab)",
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
      isEnabledForOldUI: true,
    },
  },
  '/apps/CalloutTemplate.do': {
    [PageType.list]: {
      url: "/platform/apps/com_zuora/notifications?~(selectedTab~'callout-templates-tab)",
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
      isEnabledForOldUI: true,
    },
  },
  '/apps/BaseEmailTemplate.do': {
    [PageType.list]: {
      url: "/platform/apps/com_zuora/notifications?~(selectedTab~'email-templates-tab)",
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
      isEnabledForOldUI: true,
    },
    [PageType.detail]: {
      url: '/platform/apps/com_zuora/emailtemplate/{{Id}}',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
      isEnabledForOldUI: true,
    },
  },
  '/apps/CampaignManagement.do': {
    [PageType.list]: {
      url: '/platform/apps/com_zuora/campaignmanagement',
      flags: {
        DEVELOPMENT: true,
        STAGING: true,
        SANDBOX: true,
        PRODUCTION: true,
      },
      isEnabledForOldUI: true,
    },
  },
  '/apps/TaxEngineConnection.do': {
    [PageType.edit]: {
      url: '/platform/apps/global-tax-hub/{{Id}}',
      flags: {
        DEVELOPMENT: true,
        STAGING: false,
        SANDBOX: false,
        PRODUCTION: false,
      },
    },
  },
};
