import { RedirectHandler } from '..';
import { PageType } from '../../types';

export const DataConnectRedirectHandler: RedirectHandler = ({
  location,
  queryParams,
}) => {
  const path =
    location.pathname[location.pathname.length] === '/'
      ? location.pathname.slice(0, -1)
      : location.pathname;

  if (path.endsWith('/platform/data-connect')) {
    return {
      pageType: PageType.all,
      redirectUrl: '/platform/apps/data-connect/retry',
      params: queryParams,
      isEnabledForOldUI: true,
    };
  }
  return {
    pageType: PageType.all,
    redirectUrl: path.replace(
      '/platform/data-connect',
      '/platform/apps/data-connect',
    ),
    params: queryParams,
    isEnabledForOldUI: true,
  };
};
