import { getIdentity } from '../../utils';
import { Identity } from '../../types';
import { SkipRedirectHandler } from '../type';

export const UsageListSkipRedirectHandler: SkipRedirectHandler = async () => {
  const identity: Identity = await getIdentity();
  const activeRatingEnabled =
    identity.permissions.indexOf('permission.RealTimeUsageRating') > -1;

  return activeRatingEnabled;
};
