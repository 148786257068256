import { RedirectHandler } from '..';
import { PageType } from '../../types';
import { getBasePathForObject, getCreateRedirect } from '../utils';
import { getListParams } from '../utils/getFilterParam';

export const AmendmentRedirectHandler: RedirectHandler = ({ queryParams }) => {
  const basePath = getBasePathForObject('amendment');

  const createRedirect = getCreateRedirect(basePath, queryParams, [
    'amendment',
  ]);

  // Custom Detail #1
  if (
    (queryParams.id || queryParams.amtId) &&
    queryParams.method?.includes('view')
  ) {
    return {
      pageType: PageType.detail,
      redirectUrl: `${basePath}/${queryParams.id || queryParams.amtId}`,
    };
  }

  // Custom Detail #2
  // PD-603: This will have to be revisited when Amendment Detail is replatformed.
  if (!!queryParams.csrfToken) {
    return {
      pageType: PageType.detail,
      redirectUrl: `${basePath}/${queryParams.id}`,
      params: {
        csrfToken: queryParams.csrfToken,
      },
    };
  }

  if (createRedirect) {
    return createRedirect;
  }

  return {
    pageType: PageType.list,
    redirectUrl: basePath,
    params: getListParams(queryParams),
  };
};
