import { getBasePathForObject, getCreateRedirect, getDetailRedirect } from '.';
import { RedirectHandler } from '..';
import { PageType } from '../../types';
import { getListParams } from './getFilterParam';

export const standardRedirect: (
  objectName: string,
  extras?: {
    additionalCreateMethods?: string[];
  },
  isEnabledForOldUI?: boolean,
) => RedirectHandler =
  (objectName: string, { additionalCreateMethods } = {}, isEnabledForOldUI) =>
  ({ queryParams }) => {
    const basePath = getBasePathForObject(objectName);

    const detailRedirect = getDetailRedirect(basePath, queryParams);
    const createRedirect = getCreateRedirect(
      basePath,
      queryParams,
      additionalCreateMethods,
    );

    if (detailRedirect) {
      return detailRedirect;
    }

    if (createRedirect) {
      return createRedirect;
    }

    return {
      pageType: PageType.list,
      redirectUrl: basePath,
      params: getListParams(queryParams),
      isEnabledForOldUI: isEnabledForOldUI,
    };
  };
