import { getUserEnabledNewUI } from './userPreference';
import { getReplatformedURLFromLocation, shouldSkipRedirect } from './utils';
import LARGE_ALL_SBX from './constants/opt-out-tenants/large-allsbx.json';
import LARGE_ALL_PROD from './constants/opt-out-tenants/large-allprod.json';
import LARGE_CSBX from './constants/opt-out-tenants/large-csbx.json';

// https://stackoverflow.com/a/38769139
const { currentScript } = document;

// Consumed by navigation for conditionally displaying opt-in banner experience
(window as any).zReinventedDenyList = [
  ...LARGE_ALL_SBX,
  ...LARGE_CSBX,
  ...LARGE_ALL_PROD,
];

const params = new URLSearchParams(window.location.search);

(window as any).zGetReplatformedUrl = async (location: URL) => {
  const userEnabledNewUI = await getUserEnabledNewUI();
  const replatformedURL = getReplatformedURLFromLocation(
    location,
    userEnabledNewUI,
  );
  const skipRedirect = await shouldSkipRedirect(window, location);

  if (replatformedURL && !skipRedirect) {
    return replatformedURL;
  }

  return location.toString();
};

/**
 * Scoped pages are pages that are part of a microfrontend (MFE) and are registered in the import map.
 * e.g. prod: https://git.zias.io/platform-ui/apps/blob/d89d508aff8d248da0d302ee6c272f5d992b3f1b/packages/import-maps/importmap.prod.json#L23
 * e.g. sbx: https://git.zias.io/platform-ui/apps/blob/d89d508aff8d248da0d302ee6c272f5d992b3f1b/packages/import-maps/importmap.sbx.json#L22
 */
const SCOPED_PATHS = [
  '/one-id',
  '/platform/apps/tenant-hub',
  '/platform/apps/meters',
  '/platform/apps/catalog',
];

const isNavigatingFromOrToScopedPage = (
  sourcePath: string,
  targetPath: string,
) =>
  SCOPED_PATHS.some((path) => {
    const from = sourcePath.startsWith(path);
    const to = targetPath.startsWith(path);
    return (
      // e.g. /platform/apps/meters -> /platform/apps/com_zuora/account
      (from && !to) ||
      // e.g. /platform/apps/com_zuora/account -> /platform/apps/meters
      (!from && to)
    );
  });

/**
 * There are situations where it is safe (and unsafe) to perform client-side navigation.
 * If you attempt client-side navigation when the destination is a multi-page application page,
 * the navigation will have no effect.
 *
 * 'New' UI pages are any microfrontend registered in @platform-ui/bootstrap, excluding MFEs that
 * depend on Zone.js (Angular microfrontends).
 * Any other page is considered 'old'.
 *
 * Here are some examples:
 *
 * - new UI to new UI page: ❓
 *   - if curr page is scoped, and next page is scoped: ✅
 *   - if curr page is not scoped, and next page is not scoped: ✅
 *   - if going between scoped and rest of app: ❌ (client-side NOT ok)
 * - new UI to old UI page: ❌ (client-side NOT ok)
 * - old UI to new UI page: ❌
 * - old UI to old UI page: ❌
 */
(window as any).zDoSafeNavigation = (
  // Mouse event originating from anchor tag link click
  evt: MouseEvent | null,
  url?: string | null,
) => {
  const resolvedUrl = (evt?.currentTarget as HTMLAnchorElement)?.href || url;
  if (!resolvedUrl) {
    return;
  }

  try {
    const urlObj = new URL(resolvedUrl, window.location.origin);

    if (
      isNavigatingFromOrToScopedPage(window.location.pathname, urlObj.pathname)
    ) {
      window.location.href = urlObj.toString();
      return;
    }

    const currPageIsSPA = window.location.pathname.startsWith('/platform');

    // Method is unsupported when used from 'old' UIs.
    if (!currPageIsSPA) {
      return;
    }

    const nextPageIsSPA =
      urlObj.pathname.startsWith('/platform') &&
      // Exclude analytics; zone.js must be initialized on page load, requiring server-side nav
      !urlObj.pathname.startsWith('/platform/analytics');

    if (nextPageIsSPA && !evt?.metaKey) {
      // Disable default browser behavior of server-side navigation.
      evt?.preventDefault();
      // ⚡️ Upgrade to using client-side navigation (history API) to improve performance
      window.history.pushState(null, '', resolvedUrl);
    } else {
      if (evt) {
        /* Two cases: 1. Next page is not SPA, origin is link click.
         *            2. Next page is SPA, event was a command + left click thus expecting the next page to be opened in a new tab
         * Exit early and use default browser behavior;
         * server-side navigation.
         */
        return;
      } else {
        // Next page is not SPA, no originating event. Go to new page.
        window.location.href = urlObj.toString();
      }
    }
  } catch (err) {
    console.error(err);
  }
};

// Gets replatformed URL and check for skip and user preference before redirecting
(async () => {
  const releaseUrl = 'https://cdn.zuora.com/@platform-ui/redirects/main.js';
  const isReleaseVersion =
    (currentScript as HTMLScriptElement)?.src === releaseUrl;
  const devModeRedirectVersion =
    params.get('ZNavCustomRedirectVersion') ||
    window.localStorage.getItem('ZNavCustomRedirectVersion');

  // Don't use the formal release; bail early and use the developer build
  if (isReleaseVersion && devModeRedirectVersion) {
    const redirectScript = document.createElement('script');
    redirectScript.async = false;
    redirectScript.crossOrigin = 'anonymous';
    redirectScript.src = `https://cdn.zuora.com/@platform-ui/redirects/${devModeRedirectVersion}/main.js`;
    currentScript?.after(redirectScript);
    return;
  }

  const userEnabledNewUI = await getUserEnabledNewUI();
  const replatformedURL = getReplatformedURLFromLocation(
    new URL(window.location.toString()),
    userEnabledNewUI,
  );
  const skipRedirect = await shouldSkipRedirect(
    window,
    new URL(window.location.toString()),
  );

  if (replatformedURL && !skipRedirect) {
    window.location.replace(replatformedURL);
  }
})();
