export enum PageType {
  list,
  edit,
  detail,
  create,
  all,
}

export enum EnvironmentFlag {
  Development,
  Staging,
  Sandbox,
  Production,
}

export interface AccessibleEntity {
  id: string;
  entityId: string;
  name: string;
  parentEntityId?: string;
  globalEntityId: string;
  parentNodeId?: string;
  globalNodeId: string;
  identifier: string;
  status: string;
  authEntityId: string;
  globalNode: boolean;
  multiEntity: boolean;
  provisioned: boolean;
}

// shape of "/platform/api/identity" response, plus additional properties
export interface Identity {
  coreUserId: string;
  username: string;
  workEmail: string;
  firstName: string;
  lastName: string;
  status: string;
  locale: string;
  language: string;
  twoFaEnabled: boolean;
  twoFaSetup: boolean;
  ssoEnabled: boolean;
  permissions: string[];
  defaultEntityId: string;
  defaultTenantId: string;
  tenantId: string;
  accessibleEntities?: AccessibleEntity[];
  tenantName: string;
  tenantTimeZone: string;
  platformRole: string;
  insightsRole: string;
  globalTenantStatus: string;
  globalTenantTrialEndDate?: string;
  jobTitle: string;
  jobFunctions: string[];
  // additional properties
  entityId: string;
  userId: string;
}

export type UserPreference = {
  reinvented: boolean;
  newCss: boolean;
  darkMode: boolean;
  density: 'default' | 'comfortable';
};
