export const SHOW_HIERARCHY = 'showHierarchy';

// Runs for ALL routes
export const CoreSkipRedirectHandler = async (
  window: Window,
  location: URL,
): Promise<boolean> => {
  // "ZSkipRedirect" is for usage by engineers only.
  const params = new URLSearchParams(location.search);
  const skipQueryParam =
    params.get('ZSkipRedirect') === 'true' ||
    window.localStorage.getItem('ZSkipRedirect') === 'true';

  // https://zuora.atlassian.net/browse/ZUI-5612
  const nonSupportedListView =
    params.get(SHOW_HIERARCHY) === 'true' && params.get('method') === 'list';

  if (skipQueryParam || nonSupportedListView) {
    return true;
  }

  return false;
};
