// Do not use named imports with mustache, it has issues
import Mustache from 'mustache';
import ReplatformMap from './ReplatformMap';
import MaintenanceIdentifiers from './Redirect/configs/maintenanceUrlIdentifiers.json';
import { AccessibleEntity, EnvironmentFlag, Identity, PageType } from './types';
import { RedirectMap } from './Redirect';
import queryStringParser from 'query-string';
import JSURL from 'jsurl';
import ParamsWhitelist from './ParamsWhitelist';
import Cookie from 'js-cookie';
import { fetchIdentity } from './identity';
import { DefaultSkipRedirectHandler } from './skip/handlers/Default';
import { InvoiceDetailSkipRedirectHandler } from './skip/handlers/Invoice';
import { PaymentDetailSkipRedirectHandler } from './skip/handlers/Payment';
import { SkipRedirectHandler } from './skip/type';
import { UsageListSkipRedirectHandler } from './skip/handlers/Usage';
import { CoreSkipRedirectHandler } from './skip/handlers/core';
import { GlobalTaxHubSkipRedirectHandler } from './skip/handlers/GlobalTaxHub';

export const getEnvironmentFlag = (
  hostname = window.location.hostname,
): EnvironmentFlag => {
  switch (hostname) {
    case 'host.docker.internal':
    case 'cdn.zuora.com':
    case 'localhost':
      return EnvironmentFlag.Development;
    case 'staging2.zuora.com':
      return EnvironmentFlag.Staging;
    case 'apisandbox.zuora.com':
    case 'sandbox.na.zuora.com':
    case 'sandbox.eu.zuora.com':
    case 'test.zuora.com':
    case 'test.eu.zuora.com':
      return EnvironmentFlag.Sandbox;
    default:
      return EnvironmentFlag.Production;
  }
};

export const isDevelopment = (hostname: string) => {
  return getEnvironmentFlag(hostname) === EnvironmentFlag.Development;
};

export const isStaging = (hostname: string) => {
  return getEnvironmentFlag(hostname) === EnvironmentFlag.Staging;
};

export const isSandbox = (hostname: string) => {
  return getEnvironmentFlag(hostname) === EnvironmentFlag.Sandbox;
};

export const isProduction = (hostname: string) => {
  return getEnvironmentFlag(hostname) === EnvironmentFlag.Production;
};

export const isEnvironmentFlagTruthy = (
  FLAGS: {
    DEVELOPMENT: boolean;
    STAGING: boolean;
    SANDBOX: boolean;
    PRODUCTION: boolean;
  },
  hostname = window.location.hostname,
): boolean => {
  try {
    switch (getEnvironmentFlag(hostname)) {
      // Enable new nav on development hostnames
      case EnvironmentFlag.Development:
        return FLAGS.DEVELOPMENT;
      case EnvironmentFlag.Staging:
        return FLAGS.STAGING;
      case EnvironmentFlag.Sandbox:
        return FLAGS.SANDBOX;
      case EnvironmentFlag.Production:
        return FLAGS.PRODUCTION;
    }
  } catch {
    return false;
  }
};

const userId = Cookie.get('Zuora-User-Id');
const currEntity = Cookie.get('ZuoraCurrentEntity');

export const formatLocalStorageKey = (key: string): string => {
  return `${key}.userId=${userId}&currEntityId=${currEntity}`;
};

export const getPageTypeFromParam = (
  param = '',
  queryParams?: URLSearchParams,
): PageType => {
  if (!param || param?.includes('list') || param?.includes('viewall')) {
    return PageType.list;
  } else if (param.includes('view') || param.includes('detail')) {
    return PageType.detail;
  } else if (
    (param.includes('edit') || param.includes('apply')) &&
    queryParams?.get('id')
  ) {
    return PageType.edit;
  } else {
    // 'create' // 'add' // 'amendment'
    return PageType.create;
  }
};

export const getPageTypeFromParamForInvoicePage = (param = ''): PageType => {
  if (!param || param === 'list') {
    return PageType.list;
  } else if (param === 'view') {
    return PageType.detail;
  }
  // not redirect
  return PageType.create;
};

export const getUrlPageType = (location: URL): PageType => {
  const search = new URLSearchParams(location.search);
  const method = search.get('method')?.toLowerCase();

  const hash = location.hash
    ?.split(/#|\//)
    .filter(Boolean)[0]
    ?.split('?')[0]
    ?.toLowerCase();

  const param = location.pathname?.includes('/apps') ? method : hash;

  if (location.pathname.includes('NewInvoice.do')) {
    return getPageTypeFromParamForInvoicePage(param);
  }
  return getPageTypeFromParam(param, search);
};

export const getEndpoint = (loc: URL, pathname: string): string => {
  return pathname;
};

const isMaintenanceUrl = (url: string) => {
  if (typeof url !== 'string') {
    return false;
  }
  return !!MaintenanceIdentifiers.find((identifier) =>
    url.match(new RegExp(identifier)),
  );
};

export const getReplatformedParams = (
  url: string,
  pageType: PageType,
): { queryParams: string; pathParams: object } => {
  const rawQueryString = (() => {
    try {
      return url.split('?')[1] ?? '';
    } catch {
      return '';
    }
  })();

  const whitelistedParams = Object.keys(ParamsWhitelist);
  // filter out relevant query params into object
  const parsedQueryParams = queryStringParser.parse(
    queryStringParser.pick(rawQueryString, whitelistedParams),
  );

  type Filter = {
    conditions: { field: string; operator: string; value: any }[];
    relation: 'AND';
  };

  const pathParams = {};

  if (pageType === PageType.detail && !url.startsWith('/apps')) {
    const lastSegment = url.split('/').pop();
    // handle orders detail view: /orders#/view/O-00001341 => OrderNumber
    if (lastSegment?.startsWith('O-')) {
      pathParams['OrderNumber'] = lastSegment;
    }
  }

  const convertParam = (key: string, value: any) => {
    const convertedParam = ParamsWhitelist[pageType]?.[key];
    if (
      typeof convertedParam === 'object' &&
      (convertedParam as object).hasOwnProperty('conditions')
    ) {
      return convertedParam.conditions.includes(value) ? key : undefined;
    }

    return convertedParam;
  };

  const convertedParams = Object.entries(parsedQueryParams).reduce(
    (queryParams: { filter?: Filter; [key: string]: any }, [key, value]) => {
      // A param is only "converted" if it exists in the whitelist
      const convertedParam = convertParam(key, value);
      if (pageType === PageType.list && convertedParam) {
        if (!queryParams.filter) {
          queryParams.filter = { conditions: [], relation: 'AND' };
        }

        queryParams.filter.conditions.push({
          field: convertedParam,
          operator: 'EQ',
          value,
        });
      }

      if (pageType === PageType.detail && convertedParam) {
        pathParams[convertedParam] = value;
      }

      if (pageType === PageType.edit && convertedParam) {
        if (convertedParam === 'method') {
          queryParams[convertedParam] = value;
        } else {
          pathParams[convertedParam] = value;
        }
      }

      if (pageType === PageType.create && convertedParam) {
        queryParams[convertedParam] = value;
      }

      return queryParams;
    },
    {},
  );

  const selectQueryParamSource = (pageType: PageType, url: string): string => {
    const hasConvertedParams = Object.keys(convertedParams).length > 0;

    if (hasConvertedParams) {
      return `?${JSURL.stringify(convertedParams)}`;
    } else if (url.includes('/apps/ARSettlement.do')) {
      return `?#${JSURL.stringify({
        ExtendedCreditMemoObjects: { filter: null },
        ExtendedDebitMemoObjects: { filter: null },
      })}`;
    } else if (pageType === PageType.list) {
      return `?${JSURL.stringify({ clearFilter: true })}`;
    }
    return '';
  };

  return {
    queryParams: selectQueryParamSource(pageType, url),
    pathParams,
  };
};

export const isDisabled = (
  windowLocation,
  pageType: PageType,
  customKey?: string,
) => {
  const path = customKey ?? windowLocation.pathname;
  return (
    !ReplatformMap[path] ||
    (!isEnvironmentFlagTruthy(
      ReplatformMap[path][pageType]?.flags,
      windowLocation.hostname,
    ) &&
      !isEnvironmentFlagTruthy(
        ReplatformMap[path][PageType.all]?.flags,
        windowLocation.hostname,
      ) &&
      localStorage.getItem('REDIRECT_TEST') !== 'true')
  );
};

export const getReplatformedURLFromLocation = (
  windowLocation: URL,
  userEnabledNewUI = false,
) => {
  try {
    const basePath = windowLocation.pathname;

    const customKey = Object.keys(RedirectMap).find(
      (keyUrl) => basePath.indexOf(keyUrl) >= 0,
    );

    const customHandler =
      RedirectMap[basePath] || (customKey && RedirectMap[customKey]);

    if (isMaintenanceUrl(windowLocation.href)) {
      return;
    }

    if (customHandler) {
      const getQueryParams = () => {
        const rawQueryString = (() => {
          try {
            return windowLocation.href.split('?')[1] ?? '';
          } catch {
            return '';
          }
        })();

        const parsedQueryParams = queryStringParser.parse(rawQueryString);

        return parsedQueryParams;
      };

      const customHandlerResult = customHandler({
        location: windowLocation,
        queryParams: getQueryParams(),
      });

      if (!customHandlerResult) {
        return;
      }

      if (!customHandlerResult.isEnabledForOldUI && !userEnabledNewUI) {
        return;
      }

      const { pageType, redirectUrl, params } = customHandlerResult;

      if (isDisabled(windowLocation, pageType, customKey)) {
        return;
      }

      if (params && Object.keys(params).length > 0) {
        return `${window.location.origin}${redirectUrl}?${JSURL.stringify(
          params,
        )}`;
      }

      return `${window.location.origin}${redirectUrl}`;
    }
    const pageType = getUrlPageType(windowLocation);
    const { queryParams, pathParams } = getReplatformedParams(
      windowLocation.href,
      pageType,
    );

    if (isDisabled(windowLocation, pageType)) {
      return;
    }

    const replatformedMapping = ReplatformMap[basePath]?.[pageType];

    if (!replatformedMapping.isEnabledForOldUI && !userEnabledNewUI) {
      return;
    }

    // replaces mustache ("{{ }}") path params defined in ReplatformMap
    const replatformedUrl = replatformedMapping.url
      ? Mustache.render(replatformedMapping.url, pathParams)
      : undefined;

    return replatformedUrl
      ? `${window.location.origin}${replatformedUrl}${queryParams}`
      : undefined;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const getIdentity = () => {
  try {
    const identityCacheKey = formatLocalStorageKey('ZNavIdentity');
    const identityCacheValue = window.localStorage.getItem(identityCacheKey);
    if (!identityCacheValue) {
      throw new Error('No cached identity value');
    }
    const identity = JSON.parse(identityCacheValue) as Identity;
    return Promise.resolve(identity);
  } catch {
    return fetchIdentity();
  }
};

const getSkipRedirectHandler = (location: URL): SkipRedirectHandler => {
  const params = new URLSearchParams(location.search);
  if (
    location.pathname === '/apps/NewInvoice.do' &&
    params.get('method') === 'view' &&
    (params.has('id') ||
      params.has('invoice_number') ||
      params.has('invoiceKey'))
  ) {
    return InvoiceDetailSkipRedirectHandler;
  }

  if (
    (location.pathname === '/apps/ARPayment.do' ||
      location.pathname === '/apps/NewPayment.do') &&
    params.get('method') === 'view' &&
    params.has('id')
  ) {
    return PaymentDetailSkipRedirectHandler;
  }

  if (
    location.pathname === '/apps/Usages.do' &&
    (!params.get('method') || params.get('method') === 'list')
  ) {
    return UsageListSkipRedirectHandler;
  }

  if (
    location.pathname === '/apps/TaxEngineConnection.do' &&
    (!params.get('method') || params.get('method') === 'edit')
  ) {
    return GlobalTaxHubSkipRedirectHandler;
  }

  return DefaultSkipRedirectHandler;
};

export const shouldSkipRedirect = async (window: Window, location: URL) => {
  return (
    (await CoreSkipRedirectHandler(window, location)) ||
    getSkipRedirectHandler(location)(location)
  );
};

// Get the current tenant ID.
// Works also for Multi-Entity tenants.
// Throw error when there's any exception.
export const getTenantId = async () => {
  const identity: Identity = await getIdentity();
  try {
    const currentEntity = (
      identity.accessibleEntities as AccessibleEntity[]
    ).find((entity) => entity.id === identity.entityId) as AccessibleEntity;
    return currentEntity.entityId;
  } catch (error) {
    throw new Error('Failed to get the tenant ID.');
  }
};
