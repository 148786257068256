import { RedirectHandlerArgs, RedirectHandlerResult } from '..';
import { PageType } from '../../types';

export const getDetailRedirect = (
  basePath: string,
  queryParams: RedirectHandlerArgs['queryParams'],
  isEnabledForOldUI?: boolean,
): RedirectHandlerResult | undefined => {
  if (queryParams.id && queryParams.method?.includes('view')) {
    return {
      pageType: PageType.detail,
      redirectUrl: `${basePath}/${queryParams.id}`,
      isEnabledForOldUI: isEnabledForOldUI,
    };
  }
};
