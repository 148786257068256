import { UserPreference } from './types';
import { formatLocalStorageKey, getEndpoint } from './utils';

export const USER_PREFERENCE_API =
  '/platform/gateway-proxy-v2/v1/preference/ui';

const fetchUserPreference = async (): Promise<UserPreference | undefined> => {
  return window
    .fetch(getEndpoint(window.location, USER_PREFERENCE_API), {
      method: 'GET',
      headers: { Accept: 'application/json' },
    })
    .then((res) => {
      if (!res.ok) {
        console.error('Unable to fetch user preferences');
        return;
      }

      return res
        .json()
        .then((data: { userPreference?: UserPreference; success: boolean }) => {
          if (!data.success || !data.userPreference) {
            console.error('Unable to fetch user preferences');
            return;
          }

          localStorage.setItem(
            formatLocalStorageKey('ZRedirectUserPreference'),
            JSON.stringify(data.userPreference),
          );

          return data.userPreference;
        });
    });
};

const fetchedUserPreferenceValue = fetchUserPreference();

const getUserPreference = async (): Promise<UserPreference | undefined> => {
  const cachedString = localStorage.getItem(
    formatLocalStorageKey('ZRedirectUserPreference'),
  );

  if (cachedString) {
    return JSON.parse(cachedString) as UserPreference;
  }

  return fetchedUserPreferenceValue;
};

export const getUserEnabledNewUI = async (): Promise<boolean | undefined> => {
  const userPreference = await getUserPreference();
  return userPreference?.newCss || userPreference?.reinvented;
};
