import { getTenantId } from '../../utils';
import {
  getGlobalTaxHubOptInTenants,
  getGlobalTaxHubOptOutTenants,
} from '../../constants/tenantList';
import { SkipRedirectHandler } from '../type';

export const GlobalTaxHubSkipRedirectHandler: SkipRedirectHandler = async (
  location,
) => {
  let currentTenantId;
  try {
    currentTenantId = await getTenantId();
  } catch (error) {
    return true;
  }

  const hostName = location.hostname;

  // Do not redirect when it has param skipRedirect=true
  const params = new URLSearchParams(location.search);
  if (params.get('skipRedirect') === 'true') {
    return true;
  }

  // Do not redirect for opt out tenants.
  const optOutTenants = getGlobalTaxHubOptOutTenants(hostName);
  if (currentTenantId && optOutTenants.includes(currentTenantId)) {
    return true;
  }

  // When whitelist is empty, it means toggle on for all tenants
  const optInTenants = getGlobalTaxHubOptInTenants(hostName);
  if (
    currentTenantId &&
    optInTenants.length > 0 &&
    optInTenants.indexOf(currentTenantId) === -1
  ) {
    return true;
  }

  return false;
};
