import { RedirectHandlerArgs, RedirectHandlerResult } from '..';
import ParamsWhitelist from '../../ParamsWhitelist';
import { PageType } from '../../types';

export const getFilterParam = (
  queryParams: RedirectHandlerArgs['queryParams'],
  legacyFieldMap: { [legacyField: string]: string },
) => {
  const filter = Object.entries(queryParams).reduce(
    (
      filter: {
        conditions: { field: string; operator: string; value: any }[];
        relation: 'AND';
      },
      [key, value],
    ) => {
      if (typeof key === 'string' && legacyFieldMap[key]) {
        filter.conditions.push({
          field: legacyFieldMap[key],
          operator: 'EQ',
          value,
        });
      }
      return filter;
    },
    { conditions: [], relation: 'AND' },
  );

  if (filter.conditions.length === 0) {
    return;
  }

  return filter;
};

/**
 * "ish" (invoice settlement harmonization) query param is set in Billing Navigation APIs.
 * This will allow the InvoiceSettlement and non-InvoiceSettlement versions of the UIs to co-exist.
 * https://zuora.atlassian.net/browse/CA-4162
 */
const isInvoiceSettlementHarmonizationEnabled = (
  queryParams: RedirectHandlerArgs['queryParams'],
) => {
  return queryParams['ish'] === 'true';
};

export const getListParams = (
  queryParams: RedirectHandlerArgs['queryParams'],
) => {
  const params: RedirectHandlerResult['params'] = {};
  const filter = getFilterParam(queryParams, ParamsWhitelist[PageType.list]);

  if (isInvoiceSettlementHarmonizationEnabled(queryParams)) {
    params['ish'] = true;
  } else if (filter) {
    params['filter'] = filter;
  } else {
    params['clearFilter'] = true;
  }

  return params;
};
