import { getTenantId } from '../../utils';
import {
  getInvoiceDetailOptInTenants,
  getInvoiceDetailOptOutTenants,
} from '../../constants/tenantList';
import { SkipRedirectHandler } from '../type';

export const InvoiceDetailSkipRedirectHandler: SkipRedirectHandler = async (
  location,
) => {
  let currentTenantId;
  try {
    currentTenantId = await getTenantId();
  } catch (error) {
    return true;
  }

  const optOutTenants = getInvoiceDetailOptOutTenants(location.hostname);
  if (currentTenantId && optOutTenants.indexOf(currentTenantId) > -1) {
    return true;
  }

  // when whitelist is empty, it means toggle on for all tenants
  const optInTenants = getInvoiceDetailOptInTenants(location.hostname);
  if (
    currentTenantId &&
    optInTenants.length > 0 &&
    optInTenants.indexOf(currentTenantId) === -1
  ) {
    return true;
  }

  return false;
};
