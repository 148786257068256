import { Identity } from './types';
import { getEndpoint } from './utils';

let cache: Identity | null = null;

export function fetchIdentity(): Promise<Identity> {
  if (cache) {
    return Promise.resolve(cache);
  }
  return window
    .fetch(
      getEndpoint(new URL(window.location.href), '/platform/api/identity'),
      {
        // Needed for cross-domain requests in Enver
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      },
    )
    .then((response) => response.json())
    .then((identity) => {
      if (!identity || identity.error) {
        return Promise.reject(identity?.error);
      } else {
        cache = identity as Identity;
        return cache;
      }
    });
}
