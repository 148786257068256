import { RedirectHandlerArgs, RedirectHandlerResult } from '..';
import { PageType } from '../../types';

export const getCreateRedirect = (
  basePath: string,
  queryParams: RedirectHandlerArgs['queryParams'],
  additionalMethods?: string[],
  isEnabledForOldUI?: boolean,
): RedirectHandlerResult | undefined => {
  if (
    queryParams.method == 'create' ||
    (queryParams &&
      typeof queryParams.method === 'string' &&
      additionalMethods?.includes(queryParams.method))
  ) {
    return {
      pageType: PageType.create,
      redirectUrl: `${basePath}/create`,
      isEnabledForOldUI: isEnabledForOldUI,
    };
  }
};
