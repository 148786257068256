import { RedirectHandler } from '..';
import { PageType } from '../../types';
import {
  getDetailRedirect,
  getCreateRedirect,
  getBasePathForObject,
} from '../utils';
import { getListParams } from '../utils/getFilterParam';

export const PaymentRedirectHandler: RedirectHandler = ({ queryParams }) => {
  if (
    queryParams?.method?.includes('PaymentSchedule') ||
    queryParams?.method?.includes('apply') ||
    queryParams?.method?.includes('partialUnapply') ||
    queryParams?.method?.includes('savePayment')
  ) {
    /* 
      https://zuora.atlassian.net/browse/ZUI-4374
      TODO: future this will redirect to a different set of views separate from `/com_zuora/payment`
    */
    return;
  }

  const basePath = getBasePathForObject('payment');

  const detailRedirect = getDetailRedirect(basePath, queryParams);
  const createRedirect = getCreateRedirect(basePath, queryParams);

  if (detailRedirect) {
    return detailRedirect;
  }

  if (createRedirect) {
    return createRedirect;
  }

  return {
    pageType: PageType.list,
    redirectUrl: basePath,
    params: getListParams(queryParams),
  };
};
